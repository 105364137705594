import React, {useEffect, useMemo, useRef, useState} from 'react';
import {GET_DATA_LIST, CREATE_POST_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {updatePost, deletePost, getPreSignedUrl, uploadPhoto} from "../../request/apps";
import axios from "axios";
import ReactQuill from "react-quill";
const Buffer = require('buffer/').Buffer;

const Post = () => {

    document.title = "Post";

    const token = localStorage.getItem('jwtToken');

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [postId, setPostId] = useState(null);
    const [cover_image, set_cover_image] = useState(null);

    const [value, setValue] = useState('');

    const quillRef = useRef();

    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        console.log(editor)
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            // console.log(file);
            editor.insertEmbed(editor.getSelection(), "image", "https://i.imgur.com/ktEhgvA.jpg");
            // if (/^image\//.test(file.type)) {
            //     console.log(file);
            //     const formData = new FormData();
            //     formData.append("image", file);
            //     const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
            //     const url = res?.data?.url;
            //     editor.insertEmbed(editor.getSelection(), "image", url);
            // } else {
            //     ErrorToast('You could only upload images.');
            // }
        };
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                ['image', "link"],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            handlers: {
                image: imageHandler
            }
        },
    }), []);

    $(function () {
        var app = app || {};

        // Utils
        (function ($, app) {
            'use strict';

            app.utils = {};

            app.utils.formDataSuppoerted = (function () {
                return !!('FormData' in window);
            }());

        }($, app));

        // Parsley validators
        (function ($, app) {
            'use strict';
            window.Parsley
                .addValidator('filemaxmegabytes', {
                    requirementType: 'string',
                    validateString: function (value, requirement, parsleyInstance) {
                        if (!app.utils.formDataSuppoerted) {
                            return true;
                        }

                        var file = parsleyInstance.$element[0].files;
                        var maxBytes = requirement * 1048576;

                        if (file.length === 0) {
                            return true;
                        }

                        return file.length === 1 && file[0].size <= maxBytes;

                    },
                    messages: {
                        en: 'File is to big'
                    }
                }).addValidator('filemimetypes', {
                requirementType: 'string',
                validateString: function (value, requirement, parsleyInstance) {

                    if (!app.utils.formDataSuppoerted) {
                        return true;
                    }

                    var file = parsleyInstance.$element[0].files;

                    if (file.length === 0) {
                        return true;
                    }

                    var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                    return allowedMimeTypes.indexOf(file[0].type) !== -1;

                },
                messages: {
                    en: 'Only jpeg, png is allowed'
                }
            }).addValidator('filemaxmegabytes', {
                requirementType: 'string',
                validateString: function (value, requirement, parsleyInstance) {

                    let file = parsleyInstance.$element[0].files[0];
                    let image = new Image();
                    let deferred = $.Deferred();

                    image.src = window.URL.createObjectURL(file);
                    image.onload = function() {
                        deferred.resolve();
                    };

                    return deferred.promise();
                },
                messages: {
                    en: 'Please upload square images only.'
                }
            });
        }($, app));
    });

    useEffect(() => {
        $("#topic_selected").val("");
        var table = $('#post').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Fetching Post list</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_DATA_LIST, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Post",
                        fieldname: "title",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {array.push({raw: data})});
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.title.substr(0, 100)}...`
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.date
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_post"><i class="fa fa-pencil"></i> Edit</a><a href="#" class="nav-link delete_post"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 2
                }
            ]
        });

        $('#post').on('click', '.delete_post', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            const options = {
                position: "top-right",
                hideAfter: 5
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            let raw_message = {
                id: extract_td.raw._id
            }

            deletePost(raw_message).then((result) => {
                hide();
                const _options = {
                    position: "top-right"
                };

                if(result.error !== true) {
                    $('#post').DataTable().ajax.reload(null, false);
                    cogoToast.success(result.data, _options);
                }
            });

            return false;
        });

        $('#post').on('click', '.edit_post', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            $('#title').prop("required", true);
            $('#image').prop("required", false);
            $('#description').prop("required", true);
            $('#author').prop("required", true);

            setPostId(extract_td.raw._id);
            $("#title").val(extract_td.raw.title);
            $("#description").val(extract_td.raw.description);
            $("#author").val(extract_td.raw.author);

            $('#add_post').parsley();

            $("#modalAddPost").modal("show");

            return false;
        });

    }, []);

    const handleShowModal = () => {
        setEditForm(false);
        $('#title').prop("required", true);
        $('#image').prop("required", true);
        $('#description').prop("required", true);
        $('#author').prop("required", true);

        $('#modalAddPost').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#add_post').parsley();

        $("#modalCreatePost").modal("show");
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_cover_image(reader.result);
            };
            reader.onerror = () => {
                set_cover_image(null);
            };
        }
    }

    const handleUpdatePost = (e) => {
        e.preventDefault();
        if($('#title').parsley().isValid() == false || $('#description').parsley().isValid() == false) {
            return null;
        }

        let data = {
            id: postId,
            title: $('#title').val(),
            description: $('#description').val(),
            notification: false,
            author: $('#author').val()
        }

        setStateReady(true);

        updatePost(data).then((result) => {
            setStateReady(false);

            const option = {
                position: "top-right",
                hideAfter: 3
            };

            if(!result.error) {
                cogoToast.success("Post successfully updated", option);

                $("#modalAddPost").modal("hide");
                $(".modal-backdrop").remove();

                clearAdForm();

                reloadTable();
            } else {
                cogoToast.error("Something went wrong", option);
            }
        });

        return false;
    };

    const handleAddPost = async() => {
        if($('#title').parsley().isValid() == false || $('#image').parsley().isValid() == false) {
            return null;
        }

        const option = {
            position: "top-right",
            hideAfter: 3
        };

        setStateReady(true);

        let data = {
            title: $('#title').val(),
            url: await submitPhoto(),
            description: value,
            author: $('#author').val()
        }

        axios.post(CREATE_POST_URL, data).then((response) => {
            setStateReady(false);

            cogoToast.success(response.data.payload, option);

            $("#modalAddPost").modal("hide");
            $(".modal-backdrop").remove();

            clearAdForm();

            reloadTable();
        }).catch(() => {
            setStateReady(false);
            cogoToast.error("Something went wrong", option);
        });
    };

    const clearAdForm = () => {
        $('#image').val(null);
        $('#title').val(null);
        $('#description').val(null);
        setValue(null);
        $('#add_post').parsley().destroy();
    }

    const reloadTable = () => {
        $('#post').DataTable().ajax.reload();
    };

    const submitPhoto = () => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(cover_image.replace(/^data:image\/\w+;base64,/, ""), 'base64')

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    let renderProceedButton = () => {
        if(stateReady === true) {
            return(
                <button className="btn btn-brand-02" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return editForm ? <button className="btn btn-brand-02" onClick={handleUpdatePost.bind()}>Update Post</button> : <button className="btn btn-brand-02" onClick={handleAddPost.bind()}>Create Post</button>
        }
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Posts</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Posts</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Posts List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="post" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-60p">Title</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalAddPost" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={clearAdForm.bind()}>
                                            <span aria-hidden="true">&times;</span>
                                        </a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">Add Post</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="add_post">
                                    <div id="nameWrapper" className="form-group parsley-input">
                                        <label>Title<span className="tx-danger">*</span></label>
                                        <input id="title" type="text" className="form-control"
                                               placeholder="Enter title"
                                               autoComplete="none"
                                               data-parsley-class-handler="#nameWrapper"/>
                                    </div>

                                    <div className="form-group">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload Images</label>
                                        <input id="image" type="file" className="form-control"
                                               onChange={handleFileUpload.bind()}
                                               data-parsley-filemaxmegabytes="1"
                                               data-parsley-trigger="change"
                                               data-parsley-filemimetypes="image/jpeg, image/jpg"
                                               accept="image/jpeg, image/jpg"
                                        />
                                    </div>

                                    <div id="descriptionWrapper" className="form-group parsley-input">
                                        <label>Description</label>
                                        {/*<textarea id="description" className="form-control" rows="4"*/}
                                        {/*          data-parsley-validation-threshold="10" placeholder="Enter Message..."*/}
                                        {/*          data-parsley-class-handler="#descriptionWrapper"*/}
                                        {/*>*/}
                                        {/*  </textarea>*/}
                                        <ReactQuill modules={modules} ref={quillRef} theme="snow" value={value} onChange={setValue} />
                                    </div>

                                    <div id="authorWrapper" className="form-group parsley-input">
                                        <label>Author<span className="tx-danger">*</span></label>
                                        <input id="author" type="text" className="form-control"
                                               placeholder="Enter author name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#authorWrapper"/>
                                    </div>

                                    {renderProceedButton()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Post;
