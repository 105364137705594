// const API_URL = 'http://127.0.0.1:8089/v5';
const API_URL = 'https://server.blog.gralix.co/v5';

exports.SIGNIN_URL = `${API_URL}/sign-in`;
exports.CREATE_POST_URL = `${API_URL}/create-post`;
exports.DELETE_POST_URL = `${API_URL}/delete-post`;
exports.UPDATE_POST_URL = `${API_URL}/update-post`;
exports.GET_DATA_LIST = `${API_URL}/get-data-list`;
exports.FETCH_URL = `${API_URL}/image/`;
exports.GET_PRE_SIGNED_URL = `${API_URL}/generate-pre-signed-url`;
