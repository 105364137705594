import axios from 'axios';
import {
    DELETE_POST_URL, GET_PRE_SIGNED_URL,
    UPDATE_POST_URL,
} from "../api";
import errorHandler from "../utils/errorHandler";

export const updatePost = (data) => {
    return axios.post(UPDATE_POST_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const deletePost = (data) => {
    return axios.post(DELETE_POST_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const getPreSignedUrl = () => {
    return axios.get(GET_PRE_SIGNED_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const uploadPhoto = (signed_url, binary, config) => {
    return axios.put(signed_url, binary, config).then((response) => {
        return {
            payload: response,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}
